import { defineStore } from "pinia";
import mushroom from "cem-primary-api";

export const ListUsecase = defineStore({
  id: "listUsecase",
  state: () => ({
    data: [],
  }),
  actions: {
    async getAllListUsecase() {
      try {
        const response = await mushroom.usecase.getAllAsync({
          enabledCache: false,
        });
        if (response.result) {
          this.data = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    showListUsecase: (state) => {
      return state.data;
    },
  },
});
