import { defineStore } from "pinia";
import mushroom from "cem-primary-api";

export const ListService = defineStore({
  id: "listService",
  state: () => ({
    data: [],
  }),
  actions: {
    async getAllListService() {
      try {
        const response = await mushroom.service.getAllAsync(
          {
            filters: ["type=" + localStorage.getItem("type_login")],
          },
          { enabledCache: false }
        );
        if (response.result) {
          this.data = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    showListService: (state) => {
      return state.data;
    },
  },
});
