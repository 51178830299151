import { defineStore } from "pinia";
import mushroom from "cem-primary-api";

export const ListTableScreen = defineStore({
  id: "listTableScreen",
  state: () => ({
    data: [],
  }),
  actions: {
    async getAllListTableScreen() {
      try {
        const response = await mushroom.table_screen.getAllAsync(
          {
            filters: ["type=" + localStorage.getItem("type_login")],
          },
          { enabledCache: false }
        );
        if (response.result) {
          this.data = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    showListTableScreen: (state) => {
      return state.data;
    },
  },
});
