let model = {};

// dữ liệu form
model.dataForm = {
  id: undefined, // optional - Mã tự sinh
  target_type: undefined, // required, enum(static, service, table_screen, usecase, dynamic) - Phân loại trang đích
  target_id: undefined, // optional, ref: service|table_screen|usecase|dynamic_dashboard - Không bắt buộc với target_type=static, còn lại là bắt buộc
  link: undefined, // optional - Đường dẫn - dùng cho target_type=static
  day_limit: 1, // required, min: 1 - Giới hạn số ngày
  created_time: undefined, // required - Thời điểm tạo
  creator_id: undefined, // required, ref: user - Mã người tạo
  last_modified_time: undefined, // required - Thời điểm sửa cuối
  last_modifier_id: undefined, // required, ref: user - Mã người sửa cuối
};

model.targetDomains = [
  { value: "primary", label: "Primary" },
  { value: "probe", label: "Probe" },
  { value: "bras", label: "Bras" },
];

model.targetType = [
  { value: "static", label: "Static" },
  { value: "service", label: "Service" },
  { value: "table_screen", label: "Table screen" },
  { value: "usecase", label: "Usecase" },
  { value: "dynamic", label: "Dynamic" },
];

model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "created_time",
  defaultSort: ["created_time", "ascending"],
  fields:
    "id,target_type,target_id,link,day_limit,created_time,creator_id,last_modified_time,last_modifier_id",
  filters: "",
  dataSearch: {
    value: {
      target_type: "",
      day_limit: undefined,
    },
    // valid: {
    //     // name: [ValidService.checkNameAllowVN],
    //     code: [ValidService.checkNumber],
    //     type: [ValidService.checkNameAllowVN],
    // },
    operator: {
      target_type: "",
      day_limit: undefined,
    },
  },
};
export default model;
